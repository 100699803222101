.results {
  font-size: 1rem;
  color: white;
}

.resultsTitle {
  margin-top: 35px;
  font-size: 5rem;
  text-align: center;
}

.resultsTable {
  margin: auto;
  margin-top: 30px;
  width: 60%;
  font-size: 1.5rem;
  text-align: center;
}

.sumGood {
  color: greenyellow;
}
.sumWrong {
  color: lightgray;
}
@media only screen and (max-width: 760px) {
  .resultsTable {
    margin-top: 6px;
    font-size: 1.2rem;
  }
  .resultsTitle {
    margin-top: 5px;
    font-size: 2.5rem;
  }
}
