.mainField {
  margin: auto;
  border-radius: 15px;
  width: 1100px;
  height: 800px;
  background-color: #39b54a;
}

.footer {
  margin: 3px;
  font-size: 0.9rem;
  color: lightgray;
  text-align: center;
}

.footerButton:hover {
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 760px) {
  .mainField {
    width: 370px;
  }
}
