.overlay {
  position: absolute;
  z-index: 99;
  padding: 30px;
  font-size: 1.3rem;
  color: white;
  background: #39b54a;
  width: 1040px;
  height: 620px;
}

@media only screen and (max-width: 760px) {
  .overlay {
    font-size: 1rem;
    width: 310px;
    height: 480px;
  }
}
