input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sums {
  text-align: center;
  font-size: 12rem;
  margin-top: 200px;
}

.nextButton {
  font-size: 4rem;
  margin: 0 auto;
  margin-top: 60px;
  padding: 9px;
  cursor: pointer;
  display: block;
  background-color: yellow;
  transition-duration: 0.4s;
}

.nextButton:hover {
  background-color: white;
}

input {
  font-size: 12rem;
  width: 210px;
}

@media only screen and (max-width: 760px) {
  .sums {
    margin-top: 7px;
    font-size: 5rem;
  }
  input {
    font-size: 4rem;
    width: 140px;
  }
  .nextButton {
    margin-top: 10px;
    font-size: 3rem;
    padding: 7px;
  }
}
