.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  font-size: 1.8rem;
}

.menuItem {
  padding: 4px 10px 4px 10px;
  margin: 0px 5px 0px 5px;
  width: 100px;
  text-align: center;
  color: white;
  background-color: black;
  border-radius: 10px;
}
.menuItem:hover {
  cursor: pointer;
  color: black;
  background-color: #fff200;
}

@media only screen and (max-width: 760px) {
  .menu {
    display: grid;
    justify-items: center;
  }
  .menuItem {
    margin: 4px;
  }
}
